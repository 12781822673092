export enum CookieKeys {
    session_initiated = "session_initiated",
    session_initiator = "session_initiator",
    is_first_session = "is_first_session",
    referrer = "referrer",
    utm_source = "utm_source",
    utm_medium = "utm_medium",
    utm_campaign = "utm_campaign",
    utm_term = "utm_term",
    utm_content = "utm_content",
    _lutms2 = "_lutms2",
    _lutms24 = "_lutms24",
    _futms2 = "_futms2",
    pclid = "pclid",
    gclid = "gclid",
    fbclid = "fbclid",
    _autm30d = "_autm30d",
    client_ip = "client_ip",
    environment = "environment",
    is_robot = "is_robot",
    mode_device = "mode_device",
    is_webview =  "is_webview",
    visitorppl =  "visitorppl",
    device_id = "device_id",
    token = "token",
    generic_visitorppl = "generic_visitorppl",
    generic_token = "generic_token",
    build_number = "build_number",
    app_token = "app_token",
    test_app_device = "test_app_device",
    app_type = "app_type",
    app_devicetype = "app_devicetype",
    webview_device = "webview_device",
    lang_code = "lang_code",
    vernac_ab_flag = "vernac_ab_flag",
}