import { Injectable, NgZone, OnDestroy, Renderer2, RendererFactory2 } from "@angular/core";
import { Subject, interval } from "rxjs";
import { ModeDevice } from "../constants/application/application-constants";
import { CookieKeys } from "../constants/application/cookies-constants";
import { PageView } from "../constants/events/page-view-contants";
import { AppCommonService } from "./app-common.service";
import { AuthService } from "./auth.service";
import { StorageService } from "./storage.service";

declare var clevertap: any;

@Injectable({providedIn:"root"})
export class EventService implements OnDestroy {
  isoTime: string;
  private user_id: string;
  private user_details: any;
  public identifier: string;
  private userAgent: any;
  recentEvent: any = {};
  private addtoCartHeaders = new Subject<any>();
  private devPubsubEventUrl = "https://us-central1-datapipelinedev.cloudfunctions.net/Push-to-event-policing-API";
  private prodEventUrl = "https://us-central1-datapipelineproduction.cloudfunctions.net/push_to_event_policing_api";
  public sessionData: any = {};
  isLoggedIn: any;
  cartCount: any;
  isEliteMember: boolean;
  sessionCreatedTime: number;
  isLandingPage = true;
  isFirstTime = true;
  eventArray: any = [];
  observalVar: any;
  previousPageType: any;
  isSendSessionDetEvent: boolean = false;
  actualCurrentPageData: any = {};
  visitorIp: string = "::1";
  isSSR: boolean = typeof window === "undefined";
  visibilityChangeHandler: any;
  sendSiteVisitOnNextPageView: boolean = false;
  prevPageDetail = { page_url: "", page_type: "", page_type_value: "" };
  private readonly PREV_PAGE_FROM_SESSION = "PREV_PAGE_FROM_SESSION";
  private renderer: Renderer2;

  constructor(
    private ngZone: NgZone,
    public authService: AuthService,
    public commonService: AppCommonService,
    private storageService: StorageService,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    //We do not perform any event calls from SSR (node) so not initializing
    //EventService on SSR.
    //Ideally, EventService should not be instantiated if SSR.
    if (this.isSSR) return;
    // keeping prev page data session
    let prevPagedata = this.storageService.getFromSessionStorage(this.PREV_PAGE_FROM_SESSION);
    if (prevPagedata && prevPagedata?.page_url != window.location.href) {
      this.prevPageDetail = JSON.parse(prevPagedata);
    }

    this.visitorIp = this.storageService.getCookie("client_ip");
    this.identifier = this.getIdentifier();
    this.userAgent = window.navigator.userAgent;
    let storedExpiryTime = this.storageService.getCookie("sessionExpiryTime");
    let isSessionDtailsSent = this.storageService.getCookie("isSessionDetails");
    if (isSessionDtailsSent && storedExpiryTime && storedExpiryTime > this.getPresentTime()) {
      this.isSendSessionDetEvent = false;
      this.isLandingPage = false;
      this.sessionCreatedTime = this.storageService.getCookie("sessionCreatedTime");
    } else {
      this.sessionCreatedTime = this.getPresentTime();
      // to delete cookie on session end
      this.storageService.setCookie("sessionCreatedTime", this.sessionCreatedTime);
      this.setSessionExpiry();
      this.isSendSessionDetEvent = true;
    }
    this.commonService.getDeviceRegistrationEvent().subscribe(({ id, status }) => {
      this.setSessionData();
      this.getUserData();
      let endEventData = {};
      let eventData = {
        event: "device_registration",
        device_token: id,
        permission_status: status,
        email_id: this.isLoggedIn ? this.user_details.user.email : "",
        event_type: "setEvent",
        user_status: 1,
        socket_id: "",
      };
      endEventData["session_data"] = this.sessionData;
      endEventData["event_data"] = [];
      endEventData["event_data"].push(eventData);
      if (endEventData["session_data"].identifier != "") this.eventArray.push(endEventData);
    });
    commonService.getEventHandler().subscribe(({type, paramObj})=>{
      if(type == 'sendEventsInstantly'){ this.sendEventsInstantly();}
    })

    //renew session expiry in case of tab switch/minimize window (if supported)
    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document["msHidden"] !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document["webkitHidden"] !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }
    this.visibilityChangeHandler = this.renderer.listen("document", visibilityChange, (e) => {
      this.setSessionData();
    });

    this.ngZone.runOutsideAngular(() => {
      this.observalVar = interval(5000).subscribe((val) => {
        if (this.eventArray.length > 0) {
          this.eventsHandle(this.eventArray);
        }
      });
    })
  }

  ngOnDestroy() {
    if (typeof this.visibilityChangeHandler === "function") {
      this.visibilityChangeHandler();
    }
  }

  sendEventsInstantly() {
    return new Promise((resolve, reject) => {
      if (this.eventArray.length > 0) {
        resolve(this.eventsHandle(this.eventArray));
      }
    });
  }
  getUserData() {
    this.isEliteMember = this.authService.isEliteUser();
    this.cartCount = this.commonService.getCartCount();
    this.isLoggedIn = this.authService.isUserLoggedIn();
    if (this.isLoggedIn && (this.user_id === undefined || this.user_id == null || this.user_id == "")) {
      this.user_id = this.authService.getLoggedInUserID() || "";
      this.user_details = this.authService.getLoggedInUserDetails() || "";
    } else if (!this.isLoggedIn) {
      this.user_id = "";
      this.user_details = "";
    }
  }
  setSessionExpiry() {
    let obj = { m: "30" };
    let sessionExpiryTime = Math.ceil(this.authService.getExpiryTime(obj) / 1000);
    // to delete cookie on session end
    this.storageService.setCookie("sessionExpiryTime", sessionExpiryTime);
  }
  getModeDevice() {
    let modedevice = this.storageService.getCookie("mode_device");
    let finalRes = null;
    let modeTypes = {
      [ModeDevice.mobile]: ModeDevice.MobileEvent,
      [ModeDevice.desktop]: ModeDevice.DesktopEvent,
      [ModeDevice.android]: ModeDevice.AndroidEvent,
      [ModeDevice.ios]: ModeDevice.iOSEvent,
    };
    if (modedevice) {
      finalRes = modeTypes[modedevice];
    }
    if (!finalRes) {
      finalRes = ModeDevice.MobileEvent;
    }
    return finalRes;
  }
  setSessionData() {
    if (this.storageService.getCookie("sessionExpiryTime") < this.getPresentTime()) {
      this.sessionCreatedTime = this.getPresentTime();
      this.storageService.setCookie("sessionCreatedTime", this.sessionCreatedTime);
      this.isSendSessionDetEvent = true;
    }
    this.setSessionExpiry();
    let browserData = this.getBrowserDetails();
    this.sessionData["mode_device"] = this.getModeDevice();
    this.sessionData["version"] = !this.isSSR && window?.["isPwaMode"]? "lite-14.2": "14.2";
    this.sessionData["build_number"] = 1;
    this.sessionData["user_agent"] = this.userAgent;
    this.sessionData["identifier"] = this.identifier;
    this.sessionData["device_brand"] = browserData.browser;
    this.sessionData["device_model"] = browserData.browser;
    this.sessionData["os_version"] = browserData.version;
    this.sessionData["session_id"] = this.identifier + "_" + this.sessionCreatedTime;
    this.sessionData["event_time"] = this.getPresentTime();
    this.sessionData["user_id"] = this.checkValue(this.user_id);
    this.sessionData["entity_type"] = "user";
    this.sessionData["is_logged_in"] = this.isLoggedIn ? 1 : 0;
    this.sessionData["cart_count"] = this.cartCount;
    this.sessionData["is_elite"] = this.isEliteMember ? 1 : 0;
  }
  impressionEvent(obj: any, pageData: any) {
    if (obj) {
      this.setSessionData();
      if (!pageData) pageData = this.getPageData(obj);
      const endEventData = {
        event_data: [obj],
        page_data: pageData,
        session_data: this.sessionData,
      };
      this.eventArray.push(endEventData);
    }
  }
  setLastPageDetails({ page_url, page_type, page_type_value },isOnDestroy) {
    if (!this.isSSR && (page_url !== window.location?.href || isOnDestroy) && page_url) {
      this.prevPageDetail = { page_url: "" + page_url, page_type: "" + page_type, page_type_value: "" + page_type_value };
      this.storageService.setInSessionStorage(this.PREV_PAGE_FROM_SESSION, JSON.stringify(this.prevPageDetail));
    }
  }
  updatePreviousPageDetail(isOnDestroy = false) {
    this.setLastPageDetails({ ...this.recentEvent }, isOnDestroy);
  }
  pageViewEvent(obj: any, isEventStoreDisable?: any, pageViewType?: any) {
    if (!this.isFirstTime) {
      this.isLandingPage = false;
    }
    this.updatePreviousPageDetail();
    this.getUserData();

    if (obj.event == PageView.eventName) {
      let pageData = {};
      let eventData = {};
      /* from the page start*/
      eventData["event_type"] = "setEvent";
      eventData["event"] = "page_view";
      eventData["target_entity_type"] = obj.target_entity_type;
      eventData["target_entity_id"] = obj.target_entity_id;
      eventData["x_id"] = obj.x_id;

      pageData["event_viewtype"] = obj.event_viewtype;
      pageData["event_viewtype_value"] = obj.event_viewtype_value;
      /* from the page end*/
      eventData["is_landing_page"] = this.isLandingPage;
      eventData["referrer"] = this.checkValue(this.recentEvent.page_url);
      eventData["referrer_page_type"] = this.checkValue(this.recentEvent.page_type);
      eventData["referrer_page_value"] = this.checkValue(this.recentEvent.page_type_value);
      pageData["page_name"] = obj.page_title;
      pageData["page_type"] = obj.page_type;
      pageData["page_type_value"] = obj.page_type_value;
      pageData["page_number"] = this.checkValue(obj.page_number, 0);
      pageData["page_url"] = this.checkValue(obj.page_canonical_url, window.location.href);

      switch (pageViewType) {
        case "cart_view":
          eventData["cart_product_oos"] = obj.cart_product_oos;
          eventData["list_total"] = obj.list_total;
          eventData["sub_total"] = obj.sub_total;
          eventData["total"] = obj.total;
          eventData["discount"] = obj.discount;
          eventData["discount_tax"] = obj.discount_tax;
          eventData["coupon_code"] = obj.coupon_code;
          eventData["unique_coupon_code"] = obj.unique_coupon_code;
          eventData["shipping_cost"] = obj.shipping_cost;
          eventData["cod_cost"] = obj.cod_cost;
          eventData["action_type"] = obj.actiontype;
          break;
        case "listing_view":
          eventData["count_result"] = obj.count_result;
          break;
        case "product_view":
        case "variant_view":
          eventData["mrp"] = obj.mrp;
          eventData["our_price"] = obj?.our_price;
          eventData["offer_price"] = obj.offer_price;
          eventData["stock_status"] = obj.stock_status;
          break;
        case "story_view":
          eventData["feature_type"] = obj.feature_type;
          break;
        case "feature_view":
          break;
      }

      this.setSessionData();
      let endEventData = {};
      endEventData["page_data"] = pageData;
      endEventData["session_data"] = this.sessionData;
      endEventData["event_data"] = [];
      endEventData["event_data"].push(eventData);
      if (obj.event_viewtype !== "fragment") {
        this.actualCurrentPageData = {
          page_type: obj.page_type,
          page_value: obj.page_value,
          page_title: obj.page_title,
          page_type_value: obj.page_type_value,
          page_url: this.checkValue(obj.page_canonical_url, window.location.href),
          page_number: this.checkValue(obj.page_number, 0),
        };
        if (isEventStoreDisable === undefined || isEventStoreDisable == null) {
          this.previousPageType = this.recentEvent.page_type;
          this.setRecentEvent({
            page_type: obj.page_type,
            page_value: obj.page_value,
            page_title: obj.page_title,
            page_type_value: obj.page_type_value,
            page_url: this.checkValue(obj.page_canonical_url, window.location.href),
            page_number: this.checkValue(obj.page_number, 0),
          });
        }
      }

      let sendSiteVisit = false;
      // check if previous site visit is pending
      if (
        this.sendSiteVisitOnNextPageView &&
        pageData["page_url"] &&
        pageData["page_url"].indexOf("/order") == -1 &&
        pageData["page_url"].indexOf("/checkout") == -1 &&
        pageData["page_url"].indexOf("/login") == -1 &&
        pageData["page_url"].indexOf("/register") == -1
      ) {
        this.sendSiteVisitOnNextPageView = false;
        sendSiteVisit = true;
      }
      if (this.isSendSessionDetEvent) {
        this.isSendSessionDetEvent = false;
        eventData = {};
        eventData["event"] = "session_details";
        eventData["event_campaign"] = "";
        eventData["event_medium"] = "";
        eventData["event_source"] = "";
        eventData["event_content"] = "";
        eventData["is_paid"] = 0;
        eventData["clicked_branch_link"] = false;
        eventData["gclid"] = "0";
        eventData["referrer"] = this.checkValue(this.recentEvent.page_url);
        eventData["referrer_page_type"] = this.checkValue(this.recentEvent.page_type);
        eventData["referrer_page_value"] = this.checkValue(this.recentEvent.page_type_value);
        eventData["ip"] = this.storageService.getCookie("client_ip");
        eventData["is_landing_page"] = this.isLandingPage;
        eventData["is_first_session"] = this.isFirstTime;
        eventData["x_id"] = obj.x_id;
        eventData["visitor_ipv4_address"] = this.visitorIp ? this.visitorIp : "::1";
        endEventData["event_data"].push(eventData);
        this.storageService.setCookie("isSessionDetails", true);
        sendSiteVisit = true;
        if (
          pageData["page_url"] &&
          (pageData["page_url"].indexOf("/order") != -1 ||
            pageData["page_url"].indexOf("/checkout") != -1 ||
            pageData["page_url"].indexOf("/login") != -1 ||
            pageData["page_url"].indexOf("/register") != -1)
        ) {
          sendSiteVisit = false;
          this.sendSiteVisitOnNextPageView = true;
        }
      }
      if (endEventData["session_data"].identifier != "") {
        this.eventArray.push(endEventData);
      }

      if (this.storageService.getCookie("is_robot") == "false" && this.storageService.getCookie("is_webview") == "false") {
        if (this.storageService.getCookie(CookieKeys.utm_source) && this.storageService.getCookie(CookieKeys.utm_source).toLowerCase() != "direct") {
          sendSiteVisit = true;
        }
        if (
          (this.storageService.getCookie(CookieKeys.gclid) && this.storageService.getCookie(CookieKeys.gclid).trim() != "") ||
          (this.storageService.getCookie(CookieKeys.pclid) && this.storageService.getCookie(CookieKeys.pclid).trim() != "") ||
          (this.storageService.getCookie(CookieKeys.fbclid) && this.storageService.getCookie(CookieKeys.fbclid).trim() != "")
        ) {
          sendSiteVisit = true;
        }
      }
      //send site visit and set the source cookie to 'Direct' to prevent subsequent site visits on internal routing
      if (sendSiteVisit) {
        endEventData = {};
        let finalData = [];
        let site_visit_data = this.getSiteVisitEventData();
        endEventData["event_data"] = [];
        endEventData["event_data"].push(site_visit_data);
        endEventData["page_data"] = pageData;
        endEventData["session_data"] = this.sessionData;
        finalData.push(endEventData);

        if (this.storageService.getFromStorage("isRobot") != "true") {
          this.eventsHandle(finalData, true);
        }
    
        //reset cookie to stop subsequent site visits
        this.storageService.setCookie("utm_source", "Direct");
      }
    }

    this.isFirstTime = false;
  }
  resetCurrentPageData() {
    this.actualCurrentPageData = {
      page_type: this.recentEvent.page_type,
      page_title: this.recentEvent.page_title,
      page_type_value: this.recentEvent.page_type_value,
      page_url: this.recentEvent.page_url,
      page_number: this.recentEvent.page_number,
    };
  }
  checkValue(value, default_val?: any) {
    return value !== undefined && value != null ? value : default_val != undefined ? default_val : "";
  }
  setHeaders(obj) {
    let headerData = {};
    headerData["event"] = obj.event;
    headerData["event_type"] = "setEvent";
    headerData["target_entity_id"] = this.checkValue(obj.target_entity_id);
    headerData["target_entity_type"] = this.checkValue(obj.target_entity_type);
    headerData["feature_type"] = this.checkValue(obj.feature_type);
    headerData["feature_value"] = this.checkValue(obj.feature_value);
    headerData["feature_position"] = this.checkValue(obj.feature_position);
    headerData["item_position"] = this.checkValue(obj.item_position);
    headerData["x_id"] = this.checkValue(obj.x_id);
    this.setAddtoCartHeaders(headerData);
  }
  getPageData(obj) {
    let pageData: any = {};
    pageData["page_name"] = this.recentEvent.page_title;
    pageData["page_type"] = obj.tempPageType ? obj.tempPageType : this.recentEvent.page_type;
    pageData["page_type_value"] = obj.tempPageTypeValue
      ? obj.tempPageTypeValue
      : this.recentEvent.page_type_value
        ? this.recentEvent.page_type_value
        : this.recentEvent.page_value
          ? this.recentEvent.page_value
          : "default";
    pageData["page_number"] = this.checkValue(this.recentEvent.page_number, 0);
    pageData["page_url"] = this.recentEvent.page_url;
    pageData["event_viewtype"] = this.checkValue(obj.event_view_type, "page");
    pageData["event_viewtype_value"] = "page";
    return pageData;
  }
  clickEvent(obj: any) {
    this.getUserData();
    let eventData: any = {};
    let pageData: any = this.getPageData(obj);
    switch (obj.event) {
      case "suggestion":
        eventData["event_type"] = "setEvent";
        eventData["event"] = "suggestion";
        eventData["impression_keyword"] = this.checkValue(obj.search_results);
        eventData["search_term"] = this.checkValue(obj.search_term);
        eventData["search_place"] = this.checkValue(obj.search_place);
        eventData["intent"] = this.checkValue(obj.intent_type);
        eventData["selected_search_type"] = this.checkValue(obj.search_type);
        eventData["selected_search_type_text"] = this.checkValue(obj.feature_value);
        eventData["selected_search_id"] = this.checkValue(obj.item_id);
        eventData["x_id"] = this.checkValue(obj.x_id);
        break;
      case "filter":
      case "sort":
        eventData["event"] = this.checkValue(obj.event);
        eventData["event_type"] = "setEvent";
        eventData["target_entity_id"] = this.checkValue(obj.target_entity_id);
        eventData["target_entity_type"] = this.checkValue(obj.target_entity_type);
        eventData["feature_type"] = this.checkValue(obj.feature_type);
        eventData["feature_value"] = this.checkValue(obj.feature_value);
        eventData["x_id"] = this.makeString(this.checkValue(obj.x_id));
        eventData["key"] = this.checkValue(obj.key);
        eventData["values"] = this.checkValue(obj.values);
        break;
      case "widget_click":
        eventData["event_type"] = "setEvent";
        eventData["event"] = "widget_click";
        eventData["target_entity_id"] = this.checkValue(obj.target_entity_id);
        eventData["target_entity_type"] = "item";
        eventData["item_position"] = this.checkValue(obj.item_position);
        eventData["feature_position"] = this.checkValue(obj.feature_position);
        eventData["widget_id"] = this.checkValue(obj.widget_id);
        eventData["x_id"] = this.checkValue(obj.x_id);
        eventData["stock_status"] = this.checkValue(obj.stock_status);
        eventData["mrp"] = this.checkValue(obj.mrp);
        eventData["our_price"] = this.checkValue(obj?.our_price);
        eventData["offer_price"] = this.checkValue(obj.offer_price);
        eventData["is_landing_page"] = this.isLandingPage;
        eventData["target_type"] = this.checkValue(obj.target_type);
        eventData["target_type_value"] = this.checkValue(obj.target_type_value);
        if (obj.third_party_event_params) {
          eventData["third_party_event_params"] = this.checkValue(obj.third_party_event_params);
        }
        break;
      case "listing_click":
        eventData["event_type"] = "setEvent";
        eventData["event"] = "listing_click";
        eventData["target_entity_id"] = this.checkValue(obj?.target_entity_id);
        eventData["target_entity_type"] = this.checkValue(obj?.target_entity_type);
        eventData["item_position"] = this.checkValue(obj?.item_position);
        eventData["target_type"] = this.checkValue(obj?.target_type);
        eventData["target_type_value"] = this.checkValue(obj?.target_type_value);
        eventData["x_id"] = this.checkValue(obj?.x_id);
        eventData["stock_status"] = this.checkValue(obj?.stock_status);
        eventData["mrp"] = this.checkValue(obj?.mrp);
        eventData["our_price"] = this.checkValue(obj?.our_price);
        eventData["offer_price"] = this.checkValue(obj?.offer_price);
        if (obj?.third_party_event_params) {
          eventData["third_party_event_params"] = this.checkValue(obj?.third_party_event_params);
        }
        break;
      case "feature_click":
        eventData["event_type"] = "setEvent";
        (eventData["event"] = "feature_click"), (eventData["target_entity_id"] = this.checkValue(obj.target_entity_id));
        eventData["target_entity_type"] = this.checkValue(obj.target_entity_type);
        eventData["feature_type"] = this.checkValue(obj.feature_type);
        eventData["feature_value"] = this.checkValue(obj.feature_value);
        eventData["feature_position"] = this.checkValue(obj.feature_position);
        eventData["item_position"] = this.checkValue(obj.item_position);
        eventData["x_id"] = this.checkValue(obj.x_id);
        eventData["third_party_event_params"] = this.checkValue(obj.third_party_event_params);
        break;
      case "auth_action":
        eventData["event"] = "auth_action";
        eventData["event_type"] = "setEvent";
        eventData["action_type"] = this.checkValue(obj.action_type);
        let url = this.recentEvent.page_url;
        if (!url) {
          url = location.href;
        }
        let urlarr = url.split(".com/");
        if (urlarr.length > 0 && urlarr[1] == "login") {
          eventData["reference"] = this.checkValue(obj.reference, this.previousPageType);
        } else {
          eventData["reference"] = this.checkValue(obj.reference, this.recentEvent.page_type);
        }
        this.user_id = obj.user_id;
        this.isLoggedIn = true;
        this.cartCount = obj.cart_count;
        this.isEliteMember = obj.is_elite;
        break;
      case "interaction":
        eventData["event"] = "interaction";
        eventData["action"] = obj.action;
        eventData["type"] = obj?.type;
        eventData["subtype"] = obj.subtype;
        eventData["value"] = obj.value;
        eventData["position"] = obj.position;
        eventData["x_id"] = obj.x_id;
        break;
      case "add_to_cart":
        eventData["cart_id"] = obj.cart_id;
        if (obj.sendEvent == true){
          eventData["event"] = obj.event;
          eventData["page_type"] = obj.page_type;
          eventData["page_type_value"] = obj.page_type_value;
          eventData["target_entity_id"] = obj.target_entity_id;
          eventData["target_entity_type"] = obj.target_entity_type;
          eventData["feature_position"] = obj.feature_position;
          eventData["item_position"] = obj.item_position;
          eventData["feature_type"] = obj.feature_type;
          eventData["feature_value"] = obj.feature_value;
          eventData["mrp"] = obj.mrp || obj.price;
          eventData["offer_price"] = obj.offer_price;
          eventData["our_price"] = obj.our_price;
          eventData["target_type"] = obj.target_type;
          eventData["target_type_value"] = obj.target_type_value;
          eventData["widget_id"] = obj.widget_id;
          eventData["x_id"] = obj.x_id;
          eventData["stock_status"] = obj.stock_status;
        } else {
          this.setHeaders(obj);
          return;
        }
        break;
      case "add_to_wishlist":
        if (obj.sendEvent == true){
          eventData["event"] = obj.event;
          eventData["page_type"] = obj.page_type;
          eventData["page_type_value"] = obj.page_type_value;
          eventData["target_entity_id"] = obj.target_entity_id;
          eventData["target_entity_type"] = obj.target_entity_type;
          eventData["feature_position"] = obj.feature_position;
          eventData["item_position"] = obj.item_position;
          eventData["feature_type"] = obj.feature_type;
          eventData["feature_value"] = obj.feature_value;
          eventData["mrp"] = obj.mrp || obj.price;
          eventData["offer_price"] = obj.offer_price;
          eventData["our_price"] = obj?.our_price;
          eventData["target_type"] = obj.target_type;
          eventData["target_type_value"] = obj.target_type_value;
          eventData["widget_id"] = obj.widget_id;
          eventData["x_id"] = obj.x_id;
          eventData["stock_status"] = obj.stock_status;
        } else {
          this.setHeaders(obj);
          return;
        }
        break;

      case "notify_me":
      case "delete_cart_item":
        if (obj.sendEvent == true) {
          if (obj.event == "delete_cart_item") {
            pageData["page_name"] = this.recentEvent.page_title;
            pageData["page_type"] = obj.tempPageType ? obj.tempPageType : this.recentEvent.page_type;
            pageData["page_type_value"] = this.recentEvent.page_type_value;
            pageData["page_number"] = this.checkValue(this.recentEvent.page_number, 0);
            pageData["page_url"] = this.recentEvent.page_url;
          } else {
            pageData["page_name"] = this.actualCurrentPageData.page_title;
            pageData["page_type"] = obj.tempPageType ? obj.tempPageType : this.actualCurrentPageData.page_type;
            pageData["page_type_value"] = this.actualCurrentPageData.page_type_value;
            pageData["page_number"] = this.checkValue(this.actualCurrentPageData.page_number, 0);
            pageData["page_url"] = this.actualCurrentPageData.page_url;
          }
          pageData["event_viewtype"] = this.checkValue(obj.event_view_type, "page");
          pageData["event_viewtype_value"] = "page";

          eventData["event"] = obj.event;
          eventData["event_type"] = "setEvent";
          eventData["target_entity_id"] = this.checkValue(obj.target_entity_id);
          eventData["target_entity_type"] = this.checkValue(obj.target_entity_type);
          eventData["feature_type"] = this.checkValue(obj.feature_type);
          eventData["feature_value"] = this.checkValue(obj.feature_value);
          eventData["feature_position"] = this.checkValue(obj.feature_position);
          eventData["item_position"] = this.checkValue(obj.item_position);
          eventData["x_id"] = this.checkValue(obj.x_id);

          eventData["cart_id"] = this.checkValue(obj.cart_id, obj.id);
          eventData["mrp"] = this.checkValue(obj.price);
          eventData["offer_price"] = this.checkValue(obj.offer_price);
          eventData["our_price"] = this.checkValue(obj?.our_price);
          eventData["price"] = this.checkValue(obj.price);
          eventData["stock_status"] = this.checkValue(obj.stock_status);
          if (obj.event == "notify_me") {
            eventData["email_id"] = obj.email_id;
          }
        } else {
          this.setHeaders(obj);
          return;
        }
        break;
      case "beauty_profile":
        eventData["event"] = obj?.event;
        eventData["target_entity_id"] = obj?.target_entity_id;
        eventData["target_entity_type"] = obj?.target_entity_type;
        eventData["x_id"] = obj?.x_id;
        eventData["action_type"] = obj?.action_type;
        eventData["action_id"] = obj?.action_id;
        eventData["entity_value"] = obj?.entity_value;
        break;
      case "application_errors":
        eventData = obj;
        break;
      case "activity_response":
        eventData = obj;
        break;
      default:
        return;
    }
    this.setSessionData();
    let endEventData = {};
    endEventData["page_data"] = pageData;
    endEventData["session_data"] = this.sessionData;
    endEventData["event_data"] = [];
    endEventData["event_data"].push(eventData);
    if (endEventData["session_data"].identifier != "") this.eventArray.push(endEventData);
  }
  isInt(value) {
    if (
      !isNaN(value) &&
      (function (x) {
        return (x | 0) === x;
      })(parseFloat(value))
    ) {
      return value;
    } else {
      return 0;
    }
  }
  makeString(value: any) {
    return "" + value + "";
  }
  getBrowserDetails() {
    let resp = { browser: "", version: "" };
    try {
      let ua = navigator.userAgent;
      let b;
      let browser;
      if (ua.indexOf("Opera") != -1) {
        b = browser = "Opera";
      }
      if (ua.indexOf("Firefox") != -1 && ua.indexOf("Opera") == -1) {
        b = browser = "Firefox";
        // Opera may also contains Firefox
      }
      if (ua.indexOf("Chrome") != -1) {
        b = browser = "Chrome";
      }
      if (ua.indexOf("Safari") != -1 && ua.indexOf("Chrome") == -1) {
        b = browser = "Safari";
        // Chrome always contains Safari
      }
      if (ua.indexOf("Chromium") != -1) {
        b = browser = "Chromium";
      }
      if (ua.indexOf("MSIE") != -1 && ua.indexOf("Opera") == -1 && ua.indexOf("Trident") == -1) {
        b = "MSIE";
        browser = "Internet Explorer";
        //user agent with MSIE and Opera or MSIE and Trident may exist.
      }

      if (ua.indexOf("Trident") != -1) {
        b = "Trident";
        browser = "Internet Explorer";
      }
      // now for version
      if (!ua || ua == null) {
        ua =
          "Mozilla/5.0 (iPhone; CPU iPhone OS 13_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 [FBAN/FBIOS;FBDV/iPhone8,4;FBMD/iPhone;FBSN/iOS;FBSV/13.6.1;FBSS/2;FBID/phone;FBLC/en_GB;FBOP/5]";
      }
      let version = ua.match(b + "[ /]+[0-9]+(.[0-9]+)*")[0];
      resp = { browser: browser, version: version };
    } catch (error) {
      console.log(error);
      resp = { browser: "Safari", version: "84.0.4147.125" };
    }
    return resp;
  }
  getIsoTime() {
    var date = new Date().toISOString();
    return date;
  }
  getPresentTime() {
    var timeStamp = Math.ceil(new Date().getTime() / 1000);
    return timeStamp;
  }
  getIdentifier() {
    return this.storageService.getCookie("visitorppl");
  }

  setRecentEvent(recentEvent) {
    this.recentEvent = recentEvent;
  }
  getAddtoCartHeaders() {
    return this.addtoCartHeaders.asObservable();
  }
  setAddtoCartHeaders(value) {
    this.addtoCartHeaders.next(value);
  }
  getData() {
    return this.recentEvent;
  }
  sendPageViewClevertap(params: any) {
    if (typeof clevertap == "object") {
    }
  }
  sendClickEventsClevertap(eventType: any, params: any) {
    if (typeof clevertap == "object") {
      for (let x in params) {
        if (params[x] === "" || params[x] === null || params[x] === undefined || params[x] === "undefined") {
          delete params[x];
        }
      }
    }
  }
  eventsHandle(params: any, isRegisterSiteVisit: boolean = false) {
    return new Promise((resolve) => {
      if(!isRegisterSiteVisit) this.eventArray = [];
      var http = new XMLHttpRequest();
      var paramsToPass = JSON.stringify(params);
      let pubsubEventUrl = this.prodEventUrl;
      if (this.storageService.getCookie("environment") && this.storageService.getCookie("environment") != "prod") {
        pubsubEventUrl = this.devPubsubEventUrl;
      }
      if (this.storageService.getFromStorage("isRobot") != "true") {
        http.open("POST", pubsubEventUrl, true);
        http.onreadystatechange = function () { };
        http.send(paramsToPass);
      }
      resolve(true);
    });
  }

  sendCustomerErrorEvent(obj) {
    (obj.event = "activity_response"), (obj.module = "CART_TO_ORDER");
    this.clickEvent(obj);
  }
  getSiteVisitEventData() {
    let site_visit_event = {
      event_type: "setEvent",
      event: "site_visit",
      event_source: this.storageService.getCookie(CookieKeys.utm_source),
      event_medium: this.storageService.getCookie(CookieKeys.utm_medium),
      event_campaign: this.storageService.getCookie(CookieKeys.utm_campaign),
      gclid: this.checkValue(this.storageService.getCookie(CookieKeys.gclid), ""),
      pclid: this.checkValue(this.storageService.getCookie(CookieKeys.pclid), ""),
      fbclid: this.checkValue(this.storageService.getCookie(CookieKeys.fbclid), ""),
      visitorppl: this.storageService.getCookie("visitorppl"),
      google_advertising_id: this.checkValue(this.storageService.getCookie(CookieKeys.gclid), ""),
      clicked_branch_link: "false",
      landing_page: this.checkValue(this.actualCurrentPageData.page_url, ""),
      x_id: "NA",
      session_initiator: this.storageService.getCookie(CookieKeys.session_initiator),
      properties: "NA",
      referrer: this.checkValue(this.storageService.getCookie(CookieKeys.referrer), ""),
      referrer_page_type: "NA",
      referrer_page_value: "NA",
      is_first_session: this.storageService.getCookie(CookieKeys.is_first_session),
      visitor_ipv4_address: this.storageService.getCookie("client_ip"),
    };
    return site_visit_event;
  }
}
