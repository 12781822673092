export enum PageView {
  eventName = "page_view",
}
export enum PageViewPageType {
  exitPopup = "exit_popup",
  deliveryFeedback = "feedback",
  webView = "web_view",
  writeReview = "write_review",
  productReviews = "product_reviews",
  customerImage = "customer_image",
  lipstickSurvey = "lipstick_survey",
  freebiePage = "freebie_page",
  beautyQuiz = "beautyquiz",
  searchScreen = "search_screen",
  productDetail = "product-detail",
  login = "login",
  socialLogin = "social_login",
  otpVerification = "otp_verification",
  phone = "phone",
  enterPassword = "enter_password",
  resetPassword = "reset_password",
  register = "register",
  checkout = "checkout",
  paymentMethods = "payment_methods",
  thankYouPage = "thank_you_page",
  cart = "cart",
  skinAnalyserError = "skin_analyzer_load_error",
  priceRevealPage = "price_reveal_page",
  beautyBoxPage = "beauty_box_page",
  filter = "filter",
  shopHome = "shop_home",
  offers = "offers",
  listingFavProduct = "listing_fav_product",
  registrationInfo = "registration_info",
  registrationBeautyprofile = "registration_beautyprofile",
  editProfile = "edit_profile",
  editAddress = "edit_address",
  settings = "settings",
  contributeViewAll = "contribute_view_all",
  addNewAddress = "add_new_address",
  myBeautyProfile = "my_beauty_profile",
  orderCancel = "page_order_cancel",
  accountOptions = "account_options",
  myAddress = "my_address",
  myProfileReviews = "my_profile_reviews",
  orderDetails = "order_details",
  myOrders = "my_orders",
  savedaPaymentOptions = "saved_payment_options",
  profileImage = "profile_image",
  purplleCredit = "purplle_credit",
  trackOrder = "track_order",
  walletPassbook = "wallet_passbook",
  freebieItemView = "freebie_item_view",
  variantDetail = "variant_detail",
  support = "Support",
  fDTicketForm = "FD Ticket Form",
  fDTicketSubmit = "FD Ticket Submit",
  ticketSubmit = "Ticket Submit",
  pwaPopup = "pwa_popup",
  savedAddresses = "saved_addresses",
  paymentWebview = "payment_webview"
}
export enum PageViewPageTypeValue {
  takeSurvey = "take_survey",
  claimLipstick = "claim_lipstick",
  checkOffers = "check_offers",
  default = "default",
  error = "error",
  priceRevealPage = "price_reveal_page",
  beautyBoxPage = "beauty_box_page",
  blogDetail = "blog_detail",
  freebiePageVertical = "freebie_page_vertical",
  freebiePageHorizontal = "freebie_page_horizontal",
}
export enum PageViewEventType {
  setEvent = "setEvent",
  impression = "Impression"
}
export enum PageViewEventViewtype {
  page = "page",
  fragment = "fragment",
  surveyEventViewtype = "event_viewtype",
}
export enum PageViewEventViewtypeValue {
  page = "page",
}
export enum PageViewTargetEntityType {
  default = "default",
  exitPopup = "exit_popup",
  deliveryFeedback = "shipment_id",
  writeReview = "write_review",
  productReviews = "product_reviews",
  blogViewed = "blog_viewed",
  searchScreen = "search_screen",
  product = "product",
  brand = "brand",
  theme = "theme",
  orderPlaced = "ORDER_PLACED",
  priceRevealProduct = "price_reveal_product",
  item = "item",
  listingFavProduct = "listing_fav_product",
  login = "login",
  registrationInfo = "registration_info",
  registrationBeautyprofile = "registration_beautyprofile",
  editProfile = "edit_profile",
  singleOffer = "single_offer",
  multipleOffers = "multiple_offers",
  productId = "product_id",
  offer = "offer",
  variantDetail = "variant_detail",
  pwaPopup = "pwa_popup",
  support = "Support",
  user = "User"
}
export enum PageViewTargetEntityId {
  default = "default",
  registrationInfo = "registration_info",
  registrationBeautyprofile = "registration_beautyprofile",
  editProfile = "edit_profile",
  supportHome = "support_home"
}
export enum PageViewPageName {
  deliveryFeedback = "shipment_feedback",
  orderPlaced = "ORDER_PLACED",
  cart = "cart",
  listing = "listing"
}
export enum PageViewPageTitle {
  productReviews = "product_reviews",
  surveyPageTitle = "Purplle Survey",
  search = "search",
  checkout = "checkout",
  cart = "cart",
  priceRevealPage = "Price Reveal",
  editProfile = "edit profile",
  settings = "settings",
  myContributeAndReviews = "my contribute and reviews",
  addNewAddress = "add_new_address",
  myBeautyProfile = "beautyprofile",
  orderCancel = "page order cancel",
  resetPassword = "reset password",
  termsAndConditions = "Terms & Conditions",
  myAccount = "myaccount",
  myAddress = "my_address",
  editAddress = "edit address",
  myOrders = "my orders",
  savedPaymentOptions = "Saved payment options",
  profile = "profile",
  purplleCredit = "Purplle Credit",
  creditDetails = "Credit Details",
  returnOrder = "Return Order",
  trackOrder = "track order",
  walletPassbook = "wallet passbook",
  freebies = "Select Your Freebies",
  customerService = "Customer Service",
  createTicket = "Create Ticket",
}
export enum PageViewSection {
  product = "Product",
  PRODUCT = "PRODUCT",
  default = "default"
}
export enum PageViewItemType {
  product = "product",
}
export enum PageViewType {
  productView = "product_view",
  cartView = "cart_view",
  listingView = "listing_view",
  variantView = "variant_view",
}

export enum GTMDataLayerEventName {
  add_To_cart = "add_to_cart",
  addToCart = "addToCart",
  view_product = "view_product",
  pageView = "pageView",
  clickStream = "clickStream",
}